import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    detail_modal_opened: false
};

export const DetailModalSlice = createSlice({
    name: "ProjectDetailModal",
    initialState,
    reducers: {
        open_detail_modal: (state) => {
            state.detail_modal_opened = true
        },
        close_detail_modal: (state) => {
            state.detail_modal_opened = false
        }
    }
})

// Action creators are generated for each case reducer function
export const { open_detail_modal, close_detail_modal } = DetailModalSlice.actions;

export default DetailModalSlice.reducer;