import React from 'react'
// import BackgroundStars from '../../components/BackgroundStars/BackgroundStars'
import LandingSection from '../../components/DashboardComponents/LandingSection'
import FeaturesSection from '../../components/DashboardComponents/FeaturesSection'
import './Dashboard.css'

function Dashboard() {

  return (
    <div>

      {/* <BackgroundStars /> */}

      <LandingSection />
      <div className='mt-5'>
        <FeaturesSection />
      </div>

    </div>
  )
}

export default Dashboard