import React, { useState, useEffect } from 'react'
import './HeaderComponent.css'
import { Menu } from 'react-feather'
import { Row } from 'reactstrap'
import { Button, Drawer, Modal } from 'antd'
import MenuComponent from '../Menu/MenuComponent'
import Metamask from '../../images/MetaMask_Fox.png'
import WalletConnect from '../../images/walletconnect.png'
import { TrendingUp, Copy, CheckCircle, ExternalLink } from 'react-feather'
import { getEllipsisTxt } from '../../helpers/Formatters'
import Logo from '../../images/kingsfund.png'
import { configs } from '../../Blockchain/web3.config'
import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { injected } from '../../Blockchain/connectors/metaMask'
import { wcConnector } from '../../Blockchain/connectors/walletConnect'
import { useWeb3React } from '@web3-react/core'
import { useSelector, useDispatch } from "react-redux";
import { open, close } from '../../Redux/WalletConnect'
import { logout } from '../../Redux/Auth'


function Header() {

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { margin } = useSelector((state) => state.margin)
  const { modal_opened } = useSelector((state) => state.connectModalState)
  const { userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();


  const web3React = useWeb3React()
  const { account, deactivate } = web3React

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDisconnectVisible, setIsDisconnectVisible] = useState(false)
  const [currentNetworkID, setCurrentNetworkID] = useState(0)
  const [networkName, setNetworkName] = useState('Unknown Network')
  const [addressCopy, setAddressCopy] = useState(false)


  useEffect(() => {
    console.info("Network change ", networkName)
  }, [networkName])


  //check current network if metamask installed and perform the switch network task
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', event => {
        const chainID = parseInt(event.toString(), 16)
        setCurrentNetworkID(chainID)
      });

      window.ethereum.request({ method: "eth_chainId" })
        .then(chainId => {
          setCurrentNetworkID(parseInt(chainId, 16))
        })

    }

  }, [])

  useEffect(() => {
    const network = getChainNetworkByChainId(currentNetworkID)
    console.log('currentNetworkID', currentNetworkID)
    setNetworkName(network ? network.chainName : 'Unknown Network')
    if (currentNetworkID !== 0) {
      const defaultChainId = configs.chainId
      if (defaultChainId !== currentNetworkID) {
        changeNetwork(defaultChainId)
      }
    } else {
      setNetworkName('Unknown Network')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNetworkID])


  const changeNetwork = async (chainID) => {
    const result = getChainNetworkByChainId(chainID)
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...result
          }
        ]
      });
    } catch (err) {

    }
  };

  const handleMetamaskConnect = async () => {
    try {
      await web3React.activate(injected)
      const loginPayload = {
        provider: 'injected',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {

      const loginPayload = {
        provider: 'injected',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } finally {
      dispatch(close())
    }
  }

  const handleWalletConnectConnect = async () => {
    try {
      await web3React.activate(wcConnector)
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.error('error connecting to wallet-connect ', error)
    } finally {
      dispatch(close())
    }
  }

  const handleDisconnect = () => {
    try {
      window.localStorage.removeItem("userData")
      deactivate()
      handleCancel()
    } catch (error) {
      console.error("error while logout from user ", error)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsDisconnectVisible(false)
  };

  const showDrawer = () => {
    setShowMobileMenu(true);
  };

  const closeDrawer = () => {
    setShowMobileMenu(false);
  };

  const showDisconnectModal = () => {
    setIsDisconnectVisible(true)
  }


  const copyAddress = () => {
    setAddressCopy(true)
    navigator.clipboard.writeText(account)
  }

  return (
    <div className="sticky-top bg-blue p-4">
      <Row>
        <div className='d-flex justify-content-between'>
          <div>
            {
              userToken && <Menu className='mobile-menu' onClick={showDrawer} />
            }
            {
              !userToken && <img src={Logo} style={{width: '40px'}} />
            }
          </div>

          <div>
            {
              userToken && <Button className='kingsale-primary-button' style={{marginRight: '5px'}} onClick={() => dispatch(logout())}>Logout</Button>
            }
            {
              account && userToken &&
              <Button className='kingsale-primary-button' onClick={() => showDisconnectModal(true)}>{getEllipsisTxt(account, 5)}</Button>
            }
            {
              !account && userToken &&
              <Button className='kingsale-primary-button fw-bold' onClick={() => dispatch(open())}>Connect</Button>
            }
          </div>
        </div>
      </Row>

      <Drawer placement="left" onClose={closeDrawer} visible={showMobileMenu} closable={false} width="320px">
        <div>
          <MenuComponent />
        </div>
      </Drawer>

      {/* Wallet connect modal will show up when wallet connect button clicked */}
      <Modal title="Connect to a wallet" visible={modal_opened} onOk={() => dispatch(close())} onCancel={() => dispatch(close())} footer={false}>
        <Button
          className='col-12 kingsale-primary-button'
          size="large"
          onClick={handleMetamaskConnect}>
          <img src={Metamask} alt="metamask_logo" style={{ width: '30px', marginRight: '10px' }} />
          Connect with Metamask
        </Button>

        <Button
          className='col-12 kingsale-primary-button mt-3'
          size="large"
          onClick={handleWalletConnectConnect}>
          <img src={WalletConnect} alt="wallet connect logo" style={{ width: '25px', marginRight: '10px' }} />
          Connect with Wallet Connect
        </Button>
      </Modal>

      <Modal title="Connect to wallet" visible={isDisconnectVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>

        <div className="row">
          <p className="text-muted fw-bold col-lg-8 col-sm-12 hide-on-mobile">Connected</p>
          <p className="text-lg-end col-lg-4 col-md-12 col-sm-12 fw-bold cursor" onClick={handleDisconnect}>
            <button className="btn blue-button text-light col-12" style={{ marginRight: '15px' }} onClick={handleDisconnect}>Disconnect</button>
          </p>
        </div>

        <div className='user-account-address-container'
          style={{ border: "1px solid #FFFFFF", borderRadius: "10px" }}>
          <button
            className="btn blue-button-bordered hide-on-small-devices"
            style={{ marginRight: '15px', color: "#ffffff" }}>
            <img src={Logo} style={{ width: '20px', marginRight: '15px' }} alt='' />
            {getEllipsisTxt(account, 18)}
          </button>
        </div>

        <div className="row mt-3">
          {
            !addressCopy &&
            <p className="fw-bold col-8 hide-on-mobile cursor" onClick={copyAddress} style={{ cursor: "pointer" }}>
              <Copy size={18} color="#e6bd4f" />
              <span style={{ marginLeft: '5px' }}>Copy address</span>
            </p>
          }
          {
            addressCopy &&
            <p className="fw-bold col-8 hide-on-mobile">
              <CheckCircle size={18} color="#e6bd4f" />
              <span style={{ marginLeft: '5px' }}>Copied</span>
            </p>
          }

          <p className="text-lg-end col-md-4 col-sm-6 fw-bold">
            <a
              href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${account}`}
              target="_blank"
              className="col-lg-4 col-md-6 col-sm-6  fw-bold"
              rel="noreferrer">
              <ExternalLink size={18} color="#e6bd4f" />
              <span
                style={{ marginLeft: '5px', textDecoration: "none", color: "#FFF", cursor: "pointer" }}>
                View on explorer
              </span>
            </a>
          </p>
        </div>

      </Modal>
    </div>
  )
}

export default Header