import { Card, Tag, Spin, Button, message, Modal, Image } from 'antd'
import React, { useState, useEffect } from 'react'
import ProfilePic from '../../images/kingsfund.png'
import { Row, Col } from 'reactstrap'
import Social from './Social'
import { DateTime } from 'luxon'
import { utils } from 'ethers'
import usePoolStatus from '../../Hooks/usePoolStatus'
import ReactHtmlParser from 'react-html-parser'
import NumberFormat from 'react-number-format'
import { getLiquidityProviderDetailsByRouterAddress } from '../../Blockchain/services/presale.service'
import { Edit, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from "react-redux";
import { open_detail_modal } from '../../Redux/ProjectDetailModal'
import CoverImg from '../../images/CoverImg.jpeg'
import api from '../../Services/API'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';

function PoolTokenDetails(props) {

    const { presaleSaleDetails, presaleAddress, isPresaleLoading } = props
    const [formattedStartDate, setFormattedStartDate] = useState(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"))
    const [formattedEndDate, setFormattedEndDate] = useState(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"))
    const [liquidityUnlockTime, setLiquidityUnlockTime] = useState(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"))
    const [presaleDeleteLoading, setPresaleDeleteLoading] = useState(false)
    const { userToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        isLoading: isPoolStatusLoading,
        poolStatus
    } = usePoolStatus({ poolAddress: presaleAddress })

    const calculateTokensForPool = () => {

        if (presaleSaleDetails) {
            const tokenDecimals = parseInt(presaleSaleDetails?.tokenDecimals)
            const actualTotalTokenAmount = utils.formatUnits(presaleSaleDetails?.totalTokenAmount.toString(), tokenDecimals)
            return actualTotalTokenAmount
        } else {
            return 0
        }
    }

    const getPresaleImage = () => {
        if (presaleSaleDetails && presaleSaleDetails?.logoURL) {
            return presaleSaleDetails?.logoURL
        } else {
            return ProfilePic
        }
    }

    const getYoutubrUrl = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        const videoId = (match && match[2].length === 11)
            ? match[2]
            : null;

        const iframeMarkup = <iframe className="responsive-iframe" src={`//www.youtube.com/embed/${videoId}`}
            frameborder="0" allowfullscreen></iframe>;

        return iframeMarkup
    }

    const handlePresaleDelete = async () => {
        setPresaleDeleteLoading(true)
        try {
            await api.delete(`/api/v1/presale/delete-pool-by-pool-address/${presaleAddress}`, {headers: { 'Authorization' : `Bearer ${userToken}` }})
            .then(res => {
                if(res.status === 200) {
                    message.success('Success!')
                    navigate(-1)
                }
            })
        } catch (error) {
            console.log('ERROR on deleting presale')
        }
        setPresaleDeleteLoading(false)
    }


    useEffect(() => {
        if (presaleSaleDetails && presaleSaleDetails?.startTimeTimestamp) {
            const startTimeFormatted = DateTime.fromSeconds(parseInt(presaleSaleDetails?.startTimeTimestamp), { zone: "utc" })
                .toFormat("yyyy-MM-dd HH:mm:ss")
            setFormattedStartDate(startTimeFormatted)
        }

        if (presaleSaleDetails && presaleSaleDetails?.endTimeTimestamp) {
            const endTimeFormatted = DateTime.fromSeconds(parseInt(presaleSaleDetails?.endTimeTimestamp), { zone: "utc" })
                .toFormat("yyyy-MM-dd HH:mm:ss")
            setFormattedEndDate(endTimeFormatted)
        }

        if (presaleSaleDetails && presaleSaleDetails?.liquidityUnlockTimestamp) {
            const liquidityUnlockTimeFormatted = DateTime.fromSeconds(parseInt(presaleSaleDetails?.liquidityUnlockTimestamp), { zone: "utc" })
                .toFormat("yyyy-MM-dd HH:mm:ss")
            setLiquidityUnlockTime(liquidityUnlockTimeFormatted)
        }
    }, [presaleSaleDetails])

    const getCoverImage = () => {
        if (presaleSaleDetails && presaleSaleDetails?.coverImageUrl) {
            return presaleSaleDetails?.coverImageUrl
        } else {
            return CoverImg
        }
    }

    const confirm = () => {
        Modal.confirm({
          title: 'Warning!',
          icon: <ExclamationCircleOutlined />,
          content: 'Confirm if you want to delete this presale.',
          okText: 'Confirm',
          cancelText: 'Cancle',
          onOk(){
            handlePresaleDelete()
          },
          onCancel(){
            
          }
        });
      };

    return (
        <Card className="mt-4 kingsale-card-bg">

            {
                isPresaleLoading ? (
                    <div className='d-flex mt-2 justify-content-center'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div className="cover-area" style={{ backgroundImage: `url(${getCoverImage()})` }}></div>
                )
            }

            <div className='text-end' style={{ position: 'fixed', top: '30px', right: '30px' }}>
                <Button type="primary" className='text-dark' disabled={isPresaleLoading} onClick={() => dispatch(open_detail_modal())}>
                    Edit <Edit size={14} style={{ marginLeft: '5px', marginTop: '-3px' }} />
                </Button>

                <Button type="primary" loading={presaleDeleteLoading} className='text-dark' disabled={isPresaleLoading} onClick={confirm} style={{marginLeft: '5px'}}>
                    Delete <Trash2 size={14} style={{ marginLeft: '5px', marginTop: '-3px' }} />
                </Button>
            </div>

            <Row>
                <Col lg="12" md="12" sm="12">
                    {
                        isPresaleLoading ? (
                            <div className='d-flex mt-2 justify-content-center'>
                                <Spin size="small" />
                            </div>
                        ) : (
                            <>
                                <div className='d-flex'>
                                    <div>
                                        <Image
                                            fallback={ProfilePic}
                                            preview={false}
                                            src={getPresaleImage()}
                                            alt='profile-pic'
                                            className="user-profile-pic" />
                                    </div>

                                    <div>
                                        <span className="profile-name">
                                            {/* TODO : Trim the long text max title length 100 chars */}
                                            {presaleSaleDetails ? presaleSaleDetails?.presaleName : 'Pending'} {
                                                isPoolStatusLoading ? (
                                                    <div className='loader'>
                                                        <Spin size="small" />
                                                    </div>
                                                ) : (
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {
                                                            presaleSaleDetails?.kycLink !== "" &&
                                                            <a href={presaleSaleDetails?.kycLink} target="_blank" rel="noreferrer">
                                                                <Tag color="#fa541c">
                                                                    KYC
                                                                </Tag>
                                                            </a>
                                                        }

                                                        {
                                                            presaleSaleDetails?.auditedLink !== "" &&
                                                            <a href={presaleSaleDetails?.auditedLink} target="_blank" rel="noreferrer">
                                                                <Tag color="#13c2c2">
                                                                    Audit
                                                                </Tag>
                                                            </a>
                                                        }

                                                        {
                                                            presaleSaleDetails?.safuLink !== "" &&
                                                            <a href={presaleSaleDetails?.safuLink} target="_blank" rel="noreferrer">
                                                                <Tag color="#eb2f96">
                                                                    SAFU
                                                                </Tag>
                                                            </a>
                                                        }

                                                        <Tag color={poolStatus?.statusColor}>
                                                            {poolStatus?.statusName}
                                                        </Tag>
                                                    </div>
                                                )
                                            }
                                        </span>

                                        <div style={{ marginLeft: '10px' }}>
                                            <Social presaleDetails={presaleSaleDetails}
                                                isPresaleLoading={isPresaleLoading} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Row className="mt-4">
                                        <Col>
                                            <div>
                                                {presaleSaleDetails ? <div> {ReactHtmlParser(presaleSaleDetails?.description)} </div> : <Tag color="#d3bc84">Pending</Tag>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {
                                    presaleSaleDetails?.youTubeLink &&
                                    <div>
                                        <Row className="mt-4 mb-4">
                                            <Col>
                                                <div className='video-container'>
                                                    {presaleSaleDetails?.youTubeLink && getYoutubrUrl(presaleSaleDetails?.youTubeLink)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                            </>
                        )
                    }

                </Col>
            </Row>

            <Row>
                <Col lg="12" md="12" sm="12">

                    <div className='mt-4'>

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Presale Address</span>
                            </div>

                            <div>
                                <a
                                    className="small"
                                    href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${presaleSaleDetails?.poolContractAddress}`}
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{ wordBreak: 'break-all' }}>
                                    {presaleSaleDetails ? presaleSaleDetails?.poolContractAddress : <Tag color="#d3bc84">Pending</Tag>}
                                </a>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Token Name</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? presaleSaleDetails?.tokenName : <Tag color="#d3bc84">Pending</Tag>}
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Token Address</span>
                            </div>

                            <div>
                                <div>
                                    <a
                                        className="small"
                                        href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${presaleSaleDetails?.tokenAddress}`}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ wordBreak: 'break-all' }}>
                                        {presaleSaleDetails ? presaleSaleDetails?.tokenAddress : <Tag color="#d3bc84">Pending</Tag>}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr />



                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Token Symbol</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? presaleSaleDetails?.tokenSymbol : <Tag color="#d3bc84">Pending</Tag>}
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Token Decimals</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? presaleSaleDetails?.tokenDecimals : '-'}
                                </span>
                            </div>
                        </div>
                        <hr />

                        {/* <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Fund Pool Address</span>
                            </div>

                            <div>
                                <a className="small" href="#" rel="noreferrer" target="_blank" style={{ wordBreak: 'break-all' }}>
                                    0xad09c8A0CC1bA773FED5dabb36f32Abe3668BFE8
                                </a><br />
                                <span className="small">Do not send BNB directly to the Funding pool address!</span>
                            </div>
                        </div>
                        <hr /> */}

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Tokens For Presale</span>
                            </div>

                            <div>
                                <span>
                                    {calculateTokensForPool()} {presaleSaleDetails?.tokenSymbol}
                                    {/* <NumberFormat
                                        decimalScale={3}
                                        value={presaleSaleDetails ? utils.formatUnits(presaleSaleDetails?.tokensPerBNB, presaleSaleDetails?.tokenDecimals) : 0}
                                        displayType={'text'}
                                        suffix={presaleSaleDetails ? ' ' + presaleSaleDetails?.tokenSymbol : ''}
                                        thousandSeparator={true}
                                    /> */}
                                </span>
                            </div>
                        </div>
                        <hr />

                        {/* <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Tokens For Liquidity</span>
                            </div>

                            <div>
                                <span>4.275 $EFBP 02</span>
                            </div>
                        </div>
                        <hr /> */}

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Presale Rate</span>
                            </div>

                            <div>
                                <span>
                                    1 {presaleSaleDetails ? presaleSaleDetails?.liquidityTokenName : ''} = {
                                        <NumberFormat
                                            decimalScale={3}
                                            value={presaleSaleDetails ? utils.formatUnits(presaleSaleDetails?.tokensPerBNB, presaleSaleDetails?.tokenDecimals) : 0}
                                            displayType={'text'}
                                            suffix={presaleSaleDetails ? ' ' + presaleSaleDetails?.tokenSymbol : ''}
                                            thousandSeparator={true}
                                        />
                                    }
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Listing Rate</span>
                            </div>

                            <div>
                                <span>
                                    1 {presaleSaleDetails ? presaleSaleDetails?.liquidityTokenName : ''} = {
                                        <NumberFormat
                                            decimalScale={3}
                                            value={presaleSaleDetails ? utils.formatUnits(presaleSaleDetails?.launchRate, presaleSaleDetails?.tokenDecimals) : 0}
                                            displayType={'text'}
                                            suffix={presaleSaleDetails ? ' ' + presaleSaleDetails?.tokenSymbol : ''}
                                            thousandSeparator={true}
                                        />
                                    }
                                </span>
                            </div>
                        </div>
                        <hr />

                        {/* <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Initial Market Cap (estimate)</span>
                            </div>

                            <div>
                                <span>$95,256,666,361,845</span>
                            </div>
                        </div>
                        <hr /> */}

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Soft Cap</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? utils.formatEther(presaleSaleDetails?.softCap) : ''} {presaleSaleDetails ? presaleSaleDetails?.liquidityTokenName : ''}
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Hard Cap</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? utils.formatEther(presaleSaleDetails?.hardCap) : ''} {presaleSaleDetails ? presaleSaleDetails?.liquidityTokenName : ''}
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Presale Start Time</span>
                            </div>

                            <div>
                                <span>{formattedStartDate ? formattedStartDate : 'N/A'} (UTC)</span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Presale End Time</span>
                            </div>

                            <div>
                                <span>{formattedEndDate ? formattedEndDate : 'N/A'} (UTC)</span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Whitelisting Status</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? presaleSaleDetails.isWhiteListingEnabled ? 'Active' : 'Inactive' : 'N/A'}
                                </span>
                            </div>
                        </div>
                        <hr />


                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Public Sales Time (UTC)</span>
                            </div>

                            <div>
                                <span>
                                    {
                                        presaleSaleDetails ? presaleSaleDetails.publicSaleStartTimestamp !== "0" ?
                                            DateTime.fromSeconds(parseInt(presaleSaleDetails.publicSaleStartTimestamp), { zone: "utc" }).toFormat("yyyy-MM-dd HH:mm:ss") : 'N/A' : 'N/A'}
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Listing On</span>
                            </div>

                            <div>
                                <a
                                    href={getLiquidityProviderDetailsByRouterAddress(presaleSaleDetails?.routerAddress)?.website}
                                    rel="noreferrer"
                                    target="_blank"
                                    style={{ wordBreak: 'break-all' }}>
                                    {getLiquidityProviderDetailsByRouterAddress(presaleSaleDetails?.routerAddress)?.providerName}
                                </a>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Liquidity Percent</span>
                            </div>

                            <div>
                                <span>
                                    {presaleSaleDetails ? presaleSaleDetails?.liquidityPercentage : ''}%
                                </span>
                            </div>
                        </div>
                        <hr />

                        <div className='d-md-flex justify-content-between'>
                            <div>
                                <span>Liquidity Lockup Time</span>
                            </div>

                            <div>
                                <span>{liquidityUnlockTime ? liquidityUnlockTime : 'N/A'} (UTC)</span>
                            </div>
                        </div>
                        <hr />


                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default PoolTokenDetails