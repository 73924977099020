import React, { useState, useEffect, useRef } from 'react'
import { ArrowLeftCircle } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import './PresaleDetails.css'
import PoolTokenDetails from './PoolTokenDetails'
import StatWidget from './StatWidget'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { getTheOwnerOfThePool } from '../../Blockchain/services/presale.service'
import AdminControlWidget from './AdminControlWidget'
import { Form, Modal, Spin, Input, Button, message, Checkbox, Upload, notification } from 'antd'
import { useSelector, useDispatch } from "react-redux";
import { close_detail_modal } from '../../Redux/ProjectDetailModal'
import {
    LinkOutlined,
    GlobalOutlined,
    FacebookFilled,
    TwitterOutlined,
    GithubOutlined,
    RedditOutlined,
    InstagramFilled,
    YoutubeOutlined,
    UploadOutlined
} from '@ant-design/icons'
import telegram from '../../images/telegram.png'
import discord from '../../images/discord.png'
import BundledEditor from '../../helpers/BundledEditor';
import { utils } from 'ethers'
import api from '../../Services/API'
import { logout } from '../../Redux/Auth'
import AWS from 'aws-sdk'
import moment from 'moment'

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET },
    region: process.env.REACT_APP_REGION,
})

function PresaleDetails() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { detail_modal_opened } = useSelector((state) => state.projectDetailModalState);
    const { userToken } = useSelector((state) => state.auth);
    const { poolAddress } = useParams()
    const editorRef = useRef(null)

    const [presaleDetails, setPresaleDetails] = useState(null)
    const [isPresaleLoading, setIsPresaleLoading] = useState(false)
    const [isPoolAdmin, setIsPoolAdmin] = useState(false)
    const [ownerAddress, setOwnerAddress] = useState(false)
    const [changesPresaleDescriptionValue, setChangesPresaleDescriptionValue] = useState(null) // keep updated pool description value
    const { account } = useWeb3React()
    const [reload, setReload] = useState(false)
    const [updatingLoad, setUpdatingLoad] = useState(false)

    const [logoURLValidationStatus, setLogoURLValidationStatus] = useState(null)
    const [logoURLValidationMessage, setLogoURLValidationMessage] = useState(null)

    const [coverURLValidationMessage, setCoverURLValidationMessage] = useState(null)
    const [coverURLValidationStatus, setCoverURLValidationStatus] = useState(null)

    const [logoURL, setLogoURL] = useState('')
    const [coverImageUrl, setCoverImageUrl] = useState('')

    const [uploadedLogoFile, setUploadedLogoFile] = useState(null)
    const [uploadedCoverFile, setUploadedCoverFile] = useState(null)
    const [isLogoUploading, setIsLogoUploading] = useState(false)
    const [isCoverImgUploading, setIsCoverImgUploading] = useState(false)

    const key = 'updatable';


    const uploadConfigs = {
        onRemove: () => {
            setUploadedLogoFile(null)
        },
        beforeUpload: (file) => {
            const isPNG = file.type === 'image/png'
            const isJPG = file.type === 'image/jpg'
            const isJPEG = file.type === 'image/jpeg'
            const isGIF = file.type === 'image/gif'

            if (isPNG || isJPG || isJPEG || isGIF) {
                setUploadedLogoFile(file)
            } else {
                notification['error']({
                    message: 'Invalid File Type',
                    description:
                        'Please upload .png, .jpg, .jpeg or .gif formats',
                })
                return false
            }
            return false
        },
    }

    const uploadFileToAWS = () => {
        setLogoURLValidationStatus('validating')
        setLogoURL('')
        setIsLogoUploading(true)

        const fileExtension = uploadedLogoFile.name.split('.').pop()
        const timestamp = moment().valueOf()
        const fileName = `${timestamp}.${fileExtension}`

        const params = {
            ACL: 'public-read',
            Body: uploadedLogoFile,
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: fileName
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                const progress = Math.round((evt.loaded / evt.total) * 100)
                console.log("Progress", progress)
            })
            .send((error, data) => {
                if (!error) {
                    const uploadedFileURI = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
                    setLogoURL(uploadedFileURI)
                    notification['success']({
                        message: 'File Uploaded',
                        description:
                            'Logo has been uploaded',
                    })
                    setLogoURLValidationMessage(null)
                    setLogoURLValidationStatus('success')
                    setIsLogoUploading(false)
                } else {
                    setLogoURLValidationStatus('error')
                    setLogoURL('')
                    setIsLogoUploading(false)
                }

            })
    }

    const uploadCoverToAWS = () => {
        setCoverURLValidationStatus('validating')
        setCoverImageUrl('')
        setIsCoverImgUploading(true)

        const fileExtension = uploadedCoverFile.name.split('.').pop()
        const timestamp = moment().valueOf()
        const fileName = `${timestamp}.${fileExtension}`
        const params = {
            ACL: 'public-read',
            Body: uploadedCoverFile,
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: fileName
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                const progress = Math.round((evt.loaded / evt.total) * 100)
                console.log("Progress", progress)
            })
            .send((error, data) => {
                if (!error) {
                    const uploadedFileURI = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
                    console.log(uploadedFileURI)
                    setCoverImageUrl(uploadedFileURI)
                    notification['success']({
                        key,
                        message: 'File Uploaded',
                        description:
                            'Cover Image has been uploaded',
                    })
                    setCoverURLValidationMessage(null)
                    setCoverURLValidationStatus('success')
                    setIsCoverImgUploading(false)
                } else {
                    setCoverURLValidationStatus('error')
                    setCoverImageUrl('')
                    setIsCoverImgUploading(false)
                }

            })
    }

    useEffect(() => {
        if (uploadedLogoFile) {
            uploadFileToAWS()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedLogoFile])

    useEffect(() => {
        if (uploadedCoverFile) {
            uploadCoverToAWS()
        }
    }, [uploadedCoverFile])

    const uploadCoverImgConfigs = {
        onRemove: () => {
            setUploadedCoverFile(null)
            setCoverImageUrl(null)
        },
        beforeUpload: (file) => {
            const isPNG = file.type === 'image/png'
            const isJPG = file.type === 'image/jpg'
            const isJPEG = file.type === 'image/jpeg'
            const isGIF = file.type === 'image/gif'

            if (isPNG || isJPG || isJPEG || isGIF) {
                let fileSize = file.size / 1024 / 1024; // in MiB
                console.log(fileSize)
                if (fileSize <= 5) {
                    setUploadedCoverFile(file)
                } else {
                    setUploadedCoverFile(null)
                    setCoverImageUrl(null)
                    setCoverURLValidationStatus('error')
                    notification['error']({
                        key,
                        message: 'Invalid File Size',
                        description:
                            'Please select a file less than 5MB',
                    })
                }
            } else {
                notification['error']({
                    key,
                    message: 'Invalid File Type',
                    description:
                        'Please upload .png, .jpg, .jpeg or .gif formats',
                })
                return false
            }
            return false
        },
    }


    useEffect(() => {
        setReload(false)
        if (poolAddress) {
            fetchPrivateSalePoolDetails()
            fetchThePoolOwner()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poolAddress, reload])

    useEffect(() => {
        if (ownerAddress && account) {
            if (ownerAddress.toLowerCase() === account.toLowerCase()) {
                setIsPoolAdmin(true)
            } else {
                setIsPoolAdmin(false)
            }
        } else {
            setIsPoolAdmin(false)
        }
    }, [ownerAddress, account])

    const fetchPrivateSalePoolDetails = async () => {
        try {
            setIsPresaleLoading(true)
            const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/presale/get/${poolAddress}`
            const response = await axios.get(endpoint)
            if (response.status === 200) {
                setPresaleDetails(response.data.payload || null)
                setLogoURL(response.data.payload.logoURL)
                setCoverImageUrl(response.data.payload.coverImageUrl)
            } else {
                setPresaleDetails(null)
            }
            setIsPresaleLoading(false)
        } catch (error) {
            console.error("ERROR while fetching api data for pool ", error)
            setIsPresaleLoading(false)
            setPresaleDetails(null)
        }
    }

    const fetchThePoolOwner = async () => {
        try {
            const response = await getTheOwnerOfThePool(poolAddress)
            if (response) {
                setOwnerAddress(response)
            } else {
                setOwnerAddress(null)
            }
        } catch (error) {
            setOwnerAddress(null)
            console.error("ERROR while fetching the pool owner ", error)
        }
    }

    const handleBack = () => {
        navigate(-1)
    }

    // update pool by pool address
    const onFinish = async (values) => {
        setUpdatingLoad(true)
        const _softCap = utils.parseEther(values.softCap.toString()).toString()
        const _hardCap = utils.parseEther(values.hardCap.toString()).toString()
        try {
            await api.patch(`/api/v1/presale/update-by-pool-address/${poolAddress}`,
                {
                    logoURL: logoURL,
                    coverImageUrl: coverImageUrl,
                    websiteLink: values.websiteLink,
                    facebookLink: values.facebookLink,
                    twitterLink: values.twitterLink,
                    githubLink: values.githubLink,
                    telegramLink: values.telegramLink,
                    instagramLink: values.instagramLink,
                    discordLink: values.discordLink,
                    redditLink: values.redditLink,
                    description: changesPresaleDescriptionValue ? changesPresaleDescriptionValue : presaleDetails?.description,
                    softCap: _softCap,
                    hardCap: _hardCap,
                    youTubeLink: values.youTubeLink,
                    auditedLink: values.auditedLink,
                    kycLink: values.kycLink,
                    safuLink: values.safuLink,
                    isVerifiedPool: values.isVerifiedPool
                }, { headers: { 'Authorization': `Bearer ${userToken}` } })
                .then(res => {
                    if (res.status === 200) {
                        message.success('Success!')
                        setReload(true)
                    }
                })
        } catch (error) {
            if (error.response.status === 403) {
                dispatch(logout())
            }
            message.error(error.response.data.message)
        }
        setUpdatingLoad(false)
        dispatch(close_detail_modal())
    }

    const handlePresaleDescriptionChange = (value) => {
        setChangesPresaleDescriptionValue(value)
    }

    return (
        <div className="container mb-5">
            <Row>
                <Col className="text-start">
                    <div className="d-flex">
                        <ArrowLeftCircle className="back-button" onClick={handleBack} /> <h5 style={{ marginLeft: '10px' }}>Back</h5>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg="8" md="8" sm="12">
                    <PoolTokenDetails
                        presaleSaleDetails={presaleDetails}
                        presaleAddress={poolAddress}
                        isPresaleLoading={isPresaleLoading}
                    />
                </Col>

                <Col lg="4" md="4" sm="12">
                    <StatWidget
                        presaleSaleDetails={presaleDetails}
                        presaleAddress={poolAddress}
                        isPresaleLoading={isPresaleLoading}
                        setReload={setReload}
                    />

                    {
                        isPoolAdmin ? (
                            <AdminControlWidget
                                presaleAddress={poolAddress}
                            />
                        ) : (<></>)
                    }
                </Col>
            </Row>

            <Modal title="Edit Pool Details" visible={detail_modal_opened} width={1000} footer={false} onCancel={() => dispatch(close_detail_modal())}>
                {
                    presaleDetails ?
                        <div>
                            <div className='mb-2'>
                                <span className='text-validation-error'>(*) is required field.</span>
                            </div>

                            <Form
                                name="edit_pool_details"
                                onFinish={onFinish}
                                initialValues={{
                                    logoURL: presaleDetails.logoURL,
                                    coverImageUrl: presaleDetails.coverImageUrl,
                                    websiteLink: presaleDetails.websiteLink,
                                    facebookLink: presaleDetails.facebookLink,
                                    twitterLink: presaleDetails.twitterLink,
                                    githubLink: presaleDetails.githubLink,
                                    telegramLink: presaleDetails.telegramLink,
                                    instagramLink: presaleDetails.instagramLink,
                                    discordLink: presaleDetails.discordLink,
                                    redditLink: presaleDetails.redditLink,
                                    description: presaleDetails.description,
                                    softCap: utils.formatEther(presaleDetails.softCap),
                                    hardCap: utils.formatEther(presaleDetails.hardCap),
                                    youTubeLink: presaleDetails.youTubeLink,
                                    auditedLink: presaleDetails.auditedLink,
                                    kycLink: presaleDetails.kycLink,
                                    safuLink: presaleDetails.safuLink,
                                    isVerifiedPool: presaleDetails.isVerifiedPool ? presaleDetails.isVerifiedPool : false
                                }}
                            >
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Soft Cap</span>
                                        <Form.Item
                                            name="softCap"
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Hard Cap</span>
                                        <Form.Item
                                            name="hardCap"
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="12" md="12" sm="12">
                                        <Form.Item
                                            name="isVerifiedPool"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Verified Pool</Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Logo URL<span className='required-field-warning'>*</span></span>
                                        <Form.Item
                                            name="logoURL"
                                            validateStatus={logoURLValidationStatus}
                                            help={logoURLValidationMessage}
                                            hasFeedback
                                            onChange={e => setLogoURL(e.target.value)}
                                        >
                                            <Input
                                                disabled={isLogoUploading}
                                                value={logoURL}
                                                prefix={<LinkOutlined style={{ color: '#e6bd4f' }} />}
                                            />
                                            <Upload {...uploadConfigs} maxCount={1} >
                                                <Button
                                                    style={{ marginTop: '10px' }}
                                                    // onClick={}
                                                    className='kingsale-primary-button'
                                                    icon={<UploadOutlined />}>Or Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Website <span className='required-field-warning'>*</span></span>
                                        <Form.Item
                                            name="websiteLink"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter the website!',
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            if (!value || Boolean(new URL(value))) {
                                                                return Promise.resolve();
                                                            };
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid website url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<GlobalOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Cover Image URL</span>
                                        <Form.Item
                                            name="coverImageUrl"
                                            validateStatus={coverURLValidationStatus}
                                            help={coverURLValidationMessage}
                                            hasFeedback
                                            onChange={e => setCoverImageUrl(e.target.value)}
                                        >
                                            <Input
                                                lang='en'
                                                disabled={isCoverImgUploading}
                                                value={coverImageUrl}
                                                prefix={<LinkOutlined style={{ color: '#e6bd4f' }} />}
                                                placeholder="Recommended cover size: 830x312 pixels"
                                            />
                                            <Upload {...uploadCoverImgConfigs} maxCount={1} listType="picture" >
                                                <Button
                                                    style={{ marginTop: '10px' }}
                                                    // onClick={}
                                                    className='kingsale-primary-button'
                                                ><div className='d-flex'><UploadOutlined style={{ marginTop: '3px', marginRight: '4px' }} />Or Upload</div></Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Facebook</span>
                                        <Form.Item
                                            name="facebookLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid facebook url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid facebook url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<FacebookFilled style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Twitter</span>
                                        <Form.Item
                                            name="twitterLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/g;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid twitter url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid twitter url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<TwitterOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Github</span>
                                        <Form.Item
                                            name="githubLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid github url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid github url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<GithubOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Telegram</span>
                                        <Form.Item
                                            name="telegramLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid telegram url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid telegram url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                prefix={<img src={telegram}
                                                    style={{ width: '16px' }}
                                                    alt="telegram logo" />}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Instagram</span>
                                        <Form.Item
                                            name="instagramLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid instagram url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid instagram url'));
                                                        }
                                                    },
                                                }),
                                            ]}

                                        >
                                            <Input prefix={<InstagramFilled style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Discord</span>
                                        <Form.Item
                                            name="discordLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid discord url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid discord url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                prefix={<img src={discord}
                                                    style={{ width: '18px' }}
                                                    alt="discord logo" />}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Reddit</span>
                                        <Form.Item
                                            name="redditLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid reddit url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid reddit url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<RedditOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Youtube Link</span>
                                        <Form.Item
                                            name="youTubeLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid Youtube url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid Youtube url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<YoutubeOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>Audited Link</span>
                                        <Form.Item
                                            name="auditedLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invald audit url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid audit url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<YoutubeOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>KYC Link</span>
                                        <Form.Item
                                            name="kycLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid KYC url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid KYC url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<LinkOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="6" md="6" sm="12">
                                        <span className='small'>SAFU Link</span>
                                        <Form.Item
                                            name="safuLink"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        try {
                                                            var regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                                                            if (value.match(regExp) || value === '') {
                                                                return Promise.resolve();
                                                            } else {
                                                                return Promise.reject(new Error('Invalid SAFU url'));
                                                            }
                                                        }
                                                        catch (e) {
                                                            return Promise.reject(new Error('Invalid SAFU url'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input prefix={<LinkOutlined style={{ color: '#e6bd4f' }} />} />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="12" md="12" sm="12">
                                        <span className='small'>Description</span>
                                        <Form.Item>
                                            <BundledEditor
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={presaleDetails.description}
                                                onChange={(e) => handlePresaleDescriptionChange(editorRef.current.getContent())}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                                                        'searchreplace', 'table', 'wordcount'
                                                    ],
                                                    menubar: 'file edit view insert format tools table help',
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg="12" md="12" sm="12" className='text-center'>
                                        <Form.Item>
                                            {
                                                updatingLoad ?
                                                    <Spin size="small" />
                                                    :
                                                    <Button type="primary" htmlType="submit" className='text-dark'>
                                                        Update
                                                    </Button>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        :
                        <div className='text-center'>
                            <Spin size="small" />
                        </div>
                }
            </Modal>
        </div>
    )
}

export default PresaleDetails