import React from 'react'
import { Button } from 'antd'
import Bull from '../../images/Bull.png'

function LandingSection() {
  return (
    <div>
      <div className='container-fluid'>
        <img src={Bull}
          alt="landing bull"
          className="landing-section-image hide-on-pc mt-5 mb-4" />

        <div className='hide-on-pc text-center mt-5 mx-auto'>
          <h2 className='text-uppercase primary-text landing-text fw-bold'>Welcome To Kingsale</h2>
          <h5 className='landing-text fw-bold'>A BSC Launchpad that is fit for a King</h5>
          <p className='landing-text fw-bold mt-4'>KingSale is the next big launchpad on the BSC to provide users a suit of tools
            to safely manage project presales. We offer low fees to developers and do not charge any amount of token supply.
            Our fun and welcoming community is perfect for developers to discuss their projects and for investors to keep in touch
            with the most promising new projects. Welcome!</p>

          <div className='mt-4'>
            <Button className='kingsale-primary-button fw-bold text-uppercase' size='large' style={{ marginRight: '10px' }}>Create Now</Button>
            <Button className='kingsale-primary-button fw-bold text-uppercase' size='large'>Learn More</Button>
          </div>
        </div>
      </div>

      <div className='d-md-flex justify-content-between landing-section'>
        <div className='col-md-6 my-auto hide-on-mobile'>
          <h2 className='text-uppercase primary-text landing-text fw-bold'>Welcome To Kingsale</h2>
          <h5 className='landing-text fw-bold'>A BSC Launchpad that is fit for a King</h5>
          <p className='landing-text fw-bold'>KingSale is the next big launchpad on the BSC to provide users a suit of tools
            to safely manage project presales. We offer low fees to developers and do not charge any amount of token supply.
            Our fun and welcoming community is perfect for developers to discuss their projects and for investors to keep in touch
            with the most promising new projects. Welcome!</p>

          <div className='mt-4 mb-5'>
            <Button className='kingsale-primary-button fw-bold text-uppercase' size='large' style={{ marginRight: '10px' }}>Create Now</Button>
            <Button className='kingsale-primary-button fw-bold text-uppercase' size='large'>Learn More</Button>
          </div>
        </div>

        <div className='col-md-6'>
          <img src={Bull} alt="landing bull" className="landing-section-image hide-on-mobile" />
        </div>
      </div>
    </div>
  )
}

export default LandingSection