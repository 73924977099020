import { Card, Input, notification, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import usePoolStatus from '../../Hooks/usePoolStatus'
import { useWeb3React } from '@web3-react/core'
import {
  setRewardsToken,
  getRewardTokenSetStatus
} from '../../Blockchain/services/presale.service'
import FinalizedPoolButton from './AdminZone/FinalizedPoolButton'
import PoolCancelButton from './AdminZone/PoolCancelButton'
import StartClaimingButton from './AdminZone/StartClaimingButton'
import WhiteListingAddressWidget from './AdminZone/WhiteListingAddressWidget'

function AdminControlWidget(props) {

  const { presaleAddress } = props

  const {
    isLoading: isPoolStatusLoading,
    poolStatus
  } = usePoolStatus({ poolAddress: presaleAddress })
  const { account, library } = useWeb3React()

  const [isRewardsTokenSetLoading, setIsRewardsTokenSetLoading] = useState(false)
  const [isRewardsTokenSet, setIsRewardsTokenSet] = useState(false)
  const [rewardsTokenValue, setRewardsTokenValue] = useState('')
  const [isRewardsTokenSetExecutionLoading, setIsRewardsTokenSetExecutionLoading] = useState(false)

  useEffect(() => {
    if (presaleAddress) {
      fetchRewardTokenStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleAddress])


  const handleRewardsTokenSetup = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsRewardsTokenSetExecutionLoading(false)
        return
      }

      if (!rewardsTokenValue) {
        notification['error']({
          message: 'Input Error',
          description:
            'Please enter a correct address for rewards token',
        });
        setIsRewardsTokenSetExecutionLoading(false)
        return
      }

      setIsRewardsTokenSetExecutionLoading(true)
      const response = await setRewardsToken(presaleAddress, rewardsTokenValue, library.getSigner())
      console.log("response ", response)
      setIsRewardsTokenSetExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Rewards token address has been added to the pool.',
      });
    } catch (error) {
      console.error("ERROR while adding the rewards token address ", error)
      setIsRewardsTokenSetExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }


  const fetchRewardTokenStatus = async () => {
    try {
      setIsRewardsTokenSetLoading(false)
      const rewardsTokenSetResponse = await getRewardTokenSetStatus(presaleAddress)
      setIsRewardsTokenSet(rewardsTokenSetResponse)
      setIsRewardsTokenSetLoading(false)
    } catch (error) {
      setIsRewardsTokenSet(false)
      setIsRewardsTokenSetLoading(false)
      console.error("ERROR while fetching reward token set status")
    }
  }

  return (
    <div>
      <Card className="mt-4 kingsale-card-bg">
        <div className='d-flex justify-content-center'>
          Admin Controls (Danger Zone)
        </div>

        <div className="mt-4 finalized-pool">
          <FinalizedPoolButton
            isPoolStatusLoading={isPoolStatusLoading}
            poolStatus={poolStatus}
            presaleAddress={presaleAddress}
          />
        </div>

        <div className="mt-4 finalized-pool">
          <PoolCancelButton
            isPoolStatusLoading={isPoolStatusLoading}
            poolStatus={poolStatus}
            presaleAddress={presaleAddress}
          />
        </div>

        <div className="mt-4 finalized-pool">
          <StartClaimingButton
            isPoolStatusLoading={isPoolStatusLoading}
            poolStatus={poolStatus}
            presaleAddress={presaleAddress}
          />
        </div>

        <div className="mt-4">
          <span className='input-label'>
            Set your rewards token
          </span>
        </div>
        <div className="" style={{ padding: '5px 0px' }}>
          <Input
            disabled={isRewardsTokenSetLoading || isRewardsTokenSet}
            value={isRewardsTokenSetLoading ? 'Please wait' : rewardsTokenValue}
            onChange={e => setRewardsTokenValue(e.target.value)}
          />
        </div>

        <div className='contribution-button-container'>
          <Button
            loading={isRewardsTokenSetExecutionLoading}
            onClick={handleRewardsTokenSetup}
            disabled={isRewardsTokenSetLoading || isRewardsTokenSet || !rewardsTokenValue}
            type="primary">
            Set Rewards Token
          </Button>
        </div>

        <div className="mt-4 finalized-pool">
          <WhiteListingAddressWidget
            presaleAddress={presaleAddress}
          />
        </div>

      </Card>
    </div>
  )
}

export default AdminControlWidget