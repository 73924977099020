import React, { useState, useEffect } from 'react'
import {
  getPoolWhiteListedStatus,
  whitelistUsers
} from '../../../Blockchain/services/presale.service'
import { Spin, Button, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { Row } from 'reactstrap'
import { useWeb3React } from '@web3-react/core'

export default function WhiteListingAddressWidget(props) {

  const { presaleAddress } = props

  const [isWhitelistStatusCheckLoading, setIsWhitelistStatusCheckLoading] = useState(false)
  const [whiteListStatus, setWhiteListStatus] = useState(false)
  const [whiteListedAddress, setWhiteListedAddress] = useState('')
  const [walletAddressCount, setWalletAddressCount] = useState(0)
  const [isWhiteListedExecutionLoading, setIsWhiteListedExecutionLoading] = useState(false)
  const { account, library } = useWeb3React()
  useEffect(() => {
    if (presaleAddress) {
      checkPoolWhitelistStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleAddress])

  const checkPoolWhitelistStatus = async () => {
    try {
      setIsWhitelistStatusCheckLoading(true)
      const whitelistStatusResponse = await getPoolWhiteListedStatus(presaleAddress)
      setWhiteListStatus(whitelistStatusResponse)
      setIsWhitelistStatusCheckLoading(false)
    } catch (error) {
      setWhiteListStatus(false)
      setIsWhitelistStatusCheckLoading(false)
      console.error("ERROR while checking pool whitelist status ", error)
    }
  }

  const handleWhitelistedAccountChanges = (value) => {
    let walletAddressFound = []
    const trimmedValue = value.replace(/\s/g, '');
    if (trimmedValue) {
      walletAddressFound = trimmedValue.split(",")
      setWalletAddressCount(walletAddressFound.length || 0)
      if (walletAddressFound && walletAddressFound.length > 0) {
        setWhiteListedAddress(walletAddressFound)
      } else {
        setWhiteListedAddress([])
        setWalletAddressCount(0)
      }
    } else {
      setWhiteListedAddress([])
      setWalletAddressCount(0)
    }
  }

  const handleSetWhitelistedWalletAddress = async () => {
    try {
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsWhiteListedExecutionLoading(false)
        return
      }

      if (walletAddressCount <= 0) {
        notification['error']({
          message: 'Input Error',
          description:
            'No wallet address found please check the provided details',
        });
        setIsWhiteListedExecutionLoading(false)
        return
      }

      if (walletAddressCount > 15) {
        notification['error']({
          message: 'Limit Exceeds  Error',
          description:
            'System only allows 15 wallet address to whitelisting per time',
        });
        setIsWhiteListedExecutionLoading(false)
        return
      }

      setIsWhiteListedExecutionLoading(true)
      const whitelistUserResponse = await whitelistUsers(presaleAddress, whiteListedAddress, library.getSigner())
      console.log("whitelistUserResponse", whitelistUserResponse)
      notification['success']({
        message: 'Whitelisting Complete',
        description:
          'You have successfully whitelisted provided wallet address',
      });
      setIsWhiteListedExecutionLoading(false)
      setWhiteListedAddress('')
    } catch (error) {
      setIsWhiteListedExecutionLoading(false)
      console.error("ERROR while executing token whitelisting ", error)
      notification['error']({
        message: 'Transaction Execution Error',
        description: error,
      });

    }
  }

  return (
    <div>

      {
        isWhitelistStatusCheckLoading ? (
          <div className='d-flex justify-content-center'>
            <Spin size='small' />
          </div>
        ) : (
          <>
            {
              whiteListStatus ? (
                <>
                  <Row className="mt-4">
                    <span className='input-label'>
                      Whitelist user wallet address
                    </span>
                  </Row>
                  <Row className="" style={{ padding: '0 10px' }}>

                    <TextArea
                      rows={5}
                      onChange={e => handleWhitelistedAccountChanges(e.target.value)}
                      value={whiteListedAddress}
                    />
                  </Row>

                  <Row className="mt-2">
                    <span className='wallet-count-metadata'>We have found {walletAddressCount} address</span>
                    <div className='contribution-button-container'>
                      <Button
                        loading={isWhiteListedExecutionLoading}
                        onClick={handleSetWhitelistedWalletAddress}
                        disabled={walletAddressCount === 0}
                        type="primary">
                        Set Whitelisted Accounts
                      </Button>
                    </div>
                  </Row>
                </>
              ) : (<></>)
            }

          </>
        )
      }
    </div>
  )
}
