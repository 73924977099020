import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AppstoreFilled,
  RocketFilled,
  LockFilled,
  DatabaseFilled,
  UsergroupAddOutlined,
  RobotFilled,
  BankOutlined,
  InfoCircleFilled,
  SendOutlined,
  TwitterOutlined
} from '@ant-design/icons'
import { useSelector } from "react-redux";
import './MenuComponent.css'
import { useLocation } from 'react-router-dom';

// menu logos
import fulllogo from '../../images/logo.png'
import logo from '../../images/kingsfund.png'


function MenuComponent() {

  const navigate = useNavigate()
  const location = useLocation()
  const { margin } = useSelector((state) => state.margin)

  // menu item structure
  const getItem = (label, key, icon, children, theme) => {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }

  // menu items
  const items = [
    getItem('Dashboard', '/', <AppstoreFilled />),
    getItem('Launchpad', '/launchpad', <RocketFilled />, [
      // getItem('Create Presaled', '/create-presale'),
      getItem('Presales List', '/presales-list'),
      getItem('Trending', '/trending'),
      // getItem('Live Presales', '/live-presales'),
      // getItem('Closed Presales', '/closed-presales'),
      // getItem('Upcoming Sales', '/upcoming-presales'),
    ]),
    // getItem('King Locker', '/kings-locker', <LockFilled />, [
    //   getItem('Create Token Locker', '/token-locker'),
    //   getItem('Create LP Locker', '/liquidity-locker'),
    //   getItem('Token Lock List', '/token-list'),
    //   getItem('Liquidity Lock List', '/liquidity-list'),
    // ]),
    // getItem('King Staking', '/kingstaking', <DatabaseFilled />, [
    //   getItem('Creating Staking Pool', '/create-stakingpool'),
    //   getItem('Pools', '/pools'),
    //   // getItem('Closed Pools', '/closed-pools')
    // ]),
    // getItem('Partnership', '/partnership', <UsergroupAddOutlined />),
    // getItem('Stake Tracking Bot', '/bot', <RobotFilled />),
    // getItem('KYC', '/kyc', <BankOutlined />),
    // getItem('Info', '/info', <InfoCircleFilled />),
    // getItem('Telegram', '/telegram', <SendOutlined style={{ transform: 'rotate(320deg)', marginTop: '-3px' }} />),
    // getItem('Twitter', '/twitter', <TwitterOutlined />),
  ];

  // onclick event on a menu item, navigates ti relevant route
  const onClick = (e) => {
    navigate(e.key);
  };


  return (
    <>
      {/* main logo section */}
      <div style={{ marginTop: '30px', marginLeft: '10px', marginBottom: '20px' }} >
        {
          margin ? <img alt='main-logo' src={fulllogo} style={{ width: '120px', marginTop: '-5px', marginLeft: '65px' }} />
            :
            <img alt='main-logo' src={logo} style={{ width: '40px', marginTop: '-5px', marginLeft: '10px' }} />
        }

      </div>

      {/* menu section */}
      <Menu
        mode="inline"
        selectedKeys={location.pathname}
        theme="light"
        onClick={onClick}
        style={{ background: '#15161b', padding: '1px'}}
        items={items}
      />
    </>
  )
}

export default MenuComponent
