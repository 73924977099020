import React from 'react'
import { Layout } from 'antd'

// footer logo
import rugfreecoins from '../../images/rugfreecoins.png'
import kingsfund from '../../images/kingsfund.png'

function FooterComponent() {

  const { Footer } = Layout

  return (
    <Footer className='text-center' style={{background: '#15161b'}}>
      COPYRIGHT © {new Date().getFullYear()} Developed by <a href="https://www.rugfreecoins.com" rel="noreferrer" target="_blank"> rugfreecoins</a> <br /> All rights reserved <a href='http://www.kingsfund.io' rel="noreferrer" target="_blank">kingsfund.</a><br />
      <img src={rugfreecoins} alt="rugfree_coins_logo" style={{ width: "28px" }} />
      <img src={kingsfund} alt="rugfree_coins_logo" style={{ width: "30px", marginLeft: '15px' }} />
    </Footer>
  )
}

export default FooterComponent