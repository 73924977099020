import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Pagination, Form, Input, Select, Table, Tag, Checkbox, message, Button, Spin, Tooltip, Modal, Popconfirm, Image } from 'antd'
import axios from 'axios'
import './Presales.css'
import defaultLogo from '../../images/kingsfund.png'
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { getEllipsisTxt } from '../../helpers/Formatters'

// global variable to keep trending token id list
let trendingIdList = []

function Trending() {

  const PAGE_LIMIT = 10
  const [isPoolDataLoading, setIsPoolDataLoading] = useState(false)
  const [isTrendingDataLoading, setIsTrendingDataLoading] = useState(false)
  const [poolDataList, setPoolDataList] = useState([])
  const [totalPools, setTotalPools] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchInput, setSearchInput] = useState('')
  const [filterValue, setFIlterValue] = useState('')
  const [sortByValue, setSortByValue] = useState('')
  const [dataSource, setDataSource] = useState([]);
  const [trendingDataUpdating, setTrendingDataUpdating] = useState(false)
  const [open, setOpen] = useState(false)

  const { Option } = Select;

  const presaleStatusList = [
    { name: "All Status", value: '' },
    { name: "Live", value: 'live' },
    { name: "Upcoming", value: 'upcoming' },
    { name: "Filled", value: 'filled' },
    { name: "Finalized", value: 'finalized' },
    { name: "Ended", value: 'ended' },
    { name: "Canceled", value: 'canceled' },
    { name: "Paused", value: 'paused' },
  ];

  const presaleSortList = [
    { name: 'No Filter', value: '' },
    { name: 'Hard Cap', value: 'hard-cap' },
    { name: 'Soft Cap', value: 'soft-cap' },
    { name: 'LP Percent', value: 'lp-percent' },
    { name: 'Start Time', value: 'start-time' },
    { name: 'End Time', value: 'end-time' }
  ]

  // presale search handeling
  const handlePresaleSearchChange = (value) => {
    setFIlterValue(value)
  }

  // presale sort handeling
  const handlePresaleSortChange = (value) => {
    setSortByValue(value)
  }

  const fetchTrendingData = async () => {
    setIsTrendingDataLoading(true)
    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/trending-presales/all`
      const response = await axios.get(endpoint)
      if (response.status === 200) {
        const payload = response.data.payload
        if (payload) {
          setDataSource(payload)
        } else {
          setDataSource([])
        }
        setIsTrendingDataLoading(false)
      }
    } catch (error) {
      setIsTrendingDataLoading(false)
      console.log("ERROR while fetching active pools from API ", error)
    }
  }

  // fetch trending token list
  useEffect(() => {
    fetchTrendingData()

  }, [])

  // set trending id list in the global variable
  useEffect(() => {
    setTrendingIdList()
  }, [poolDataList])

  const setTrendingIdList = () => {
    for (var index = 0; index < dataSource.length; index++) {
      if (trendingIdList.indexOf(dataSource[index].presaleContractAddress) === -1) {
        trendingIdList.push(dataSource[index].presaleContractAddress)
      }
    }
  }

  // fetch pools when there is a change in sort, search, page
  useEffect(() => {
    fetchActivePools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByValue, filterValue, currentPage])


  // handle checkbox event by user
  const handleCheck = (e, id, poolContractAddress, presaleName) => {

    if (e.target.checked) {
      if (trendingIdList.length < 20) {
        trendingIdList.push(poolContractAddress)
        addToTrendingList(id, poolContractAddress, presaleName)
      } else {
        message.error('maximum limit reached')
        fetchActivePools('')
      }
    } else {
      for (var index = 0; index < trendingIdList.length; index++) {
        if (poolContractAddress === trendingIdList[index]) {
          trendingIdList.splice(index, 1);
          removeFromTrendingList(poolContractAddress)
          console.log(trendingIdList)
        }
      }
    }
  };

  // add token related data to data source array
  const addToTrendingList = (id, poolContractAddress, presaleName) => {

    let initialState = {
      poolData: '',
      id: '',
      presaleContractAddress: '',
      tokenSymbol: ''
    }

    for (var index = 0; index < poolDataList.length; index++) {
      if (poolContractAddress === poolDataList[index].poolContractAddress) {
        initialState.id = id
        initialState.poolData = presaleName
        initialState.presaleContractAddress = poolDataList[index].poolContractAddress
        initialState.tokenSymbol = poolDataList[index].tokenSymbol
        setDataSource([...dataSource, initialState]);
      }
    }
  }

  const removeFromTrendingList = (poolContractAddress) => {
    for (var index = 0; index < poolDataList.length; index++) {
      if (poolContractAddress === poolDataList[index].poolContractAddress) {
        setDataSource(dataSource.filter(item => item.presaleContractAddress !== poolContractAddress));
        // updateList(list.filter(item => item.name !== name));
      }
    }
  }


  // all token details data table
  const columns = [
    {
      title: "Trending",
      render: (_, record) => (
        <Checkbox checked={trendingIdList.includes(record.poolContractAddress)} onChange={(e) => handleCheck(e, record.id, record.poolContractAddress, record.presaleName)}></Checkbox>
      )
    },
    {
      title: 'Name',
      dataIndex: 'presaleName',
      render: (_, record) => (
        <>
          <Image
            fallback={defaultLogo}
            preview={false}
            src={record.logoURL ? record.logoURL : defaultLogo}
            style={{ width: '20px', marginRight: '10px', borderRadius: '50%' }}
            alt={record?.presaleName}
            className="card-logo" />

          <span>{record.presaleName}</span>
        </>
      )
    },
    {
      title: 'Token Symbol',
      dataIndex: 'tokenSymbol'
    },
    {
      title: 'Status',
      render: (_, record) => (
        <Tag color="#faad14"><span className='text-dark'>{record.status}</span></Tag>
      )
    },
    {
      title: 'Details',
      render: (text, record) => (<Link to={`/presale-details/${record.poolContractAddress}`}>view</Link>)
    }
  ];

  const fetchActivePools = async (seletedFileterValue) => {
    setIsPoolDataLoading(true)
    setPoolDataList([])
    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/presale/all?page=${currentPage}&limit=${PAGE_LIMIT}&status=${seletedFileterValue}&search=${searchInput}`
      const response = await axios.get(endpoint)
      if (response.status === 200) {
        const payload = response.data.payload
        if (payload) {
          const total = payload.meta.totalItems
          setTotalPools(total)
          setPoolDataList(payload.items)
        } else {
          setPoolDataList([])
        }
        setIsPoolDataLoading(false)
      }
    } catch (error) {
      setIsPoolDataLoading(false)
      console.log("ERROR while fetching active pools from API ", error)
    }
  }

  // update trending list
  const handleTrendingListUpdate = async () => {
    setTrendingDataUpdating(true)
    console.log(dataSource)
    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/trending-presales/update-list`
      const response = await axios.post(endpoint, dataSource)
      if (response.status === 200) {
        message.success('Trending list updated')
      }
    } catch (error) {
      console.log("ERROR while updating trending list from API ", error)
    }

    setTrendingDataUpdating(false)
  }

  //delete token from trending list
  const deleteTrendingItem = async (id) => {
    setTrendingDataUpdating(true)
    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/trending-presales/delete/${id}`
      const response = await axios.delete(endpoint)
      if (response.status === 200) {
        message.success('Trending item deleted')
      }
      setOpen(false)
      fetchTrendingData()
    } catch (error) {
      console.log("ERROR while deleting trending list from API ", error)
    }

    setTrendingDataUpdating(false)
  }

  const onChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber)
    }
  }

  useEffect(() => {
    fetchActivePools(filterValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterValue, searchInput])


  //   Table 02 related data
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999',
      }}
    />
  ));


  const handleCancel = () => {
    setOpen(false)
  }

  const showPopconfirm = () => {
    setOpen(true)
  }

  const trendingColumns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Rank',
      render: (_, record, index) => (index + 1)
    },
    {
      title: 'Name',
      dataIndex: 'poolData'
    },
    {
      title: 'Token Address',
      render: (_, record) => (<Tooltip title={record.presaleContractAddress}>{getEllipsisTxt(record.presaleContractAddress, 5)}</Tooltip>),
      className: 'drag-visible',
    },
    {
      title: 'Token Symbol',
      dataIndex: 'tokenSymbol',
      className: 'drag-visible',
    },
    {
      title: 'Actions',
      render: (_, record) => (
        <>
          <Popconfirm
            title="Do you want to remove this item?"
            open={open}
            onConfirm={() => deleteTrendingItem(record.id)}
            okButtonProps={{
              loading: trendingDataUpdating,
            }}
            onCancel={handleCancel}
          >
            <Button type="primary" onClick={showPopconfirm}>
              Remove
            </Button>
          </Popconfirm>
        </>
      ),
      className: 'drag-visible',
    },
  ];

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      console.log('Sorted items: ', newData);
      setDataSource(newData);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className='mt-3 mb-5 presale-padding'>
      <Row>
        <Col lg="8" md="8" sm="8">
          <span className='small'>Enter token name, symbol or token address</span>
          <Form.Item
            name="searchInput"
            hasFeedback
          >
            <Input
              value={searchInput}
              name="tokenAddress"
              onChange={e => setSearchInput(e.target.value)}
              size='large'
              className='rounded-input'
              placeholder='Enter token name, symbol or token address' />
          </Form.Item>
        </Col>
        <Col lg="2" md="2" sm="2">
          <span className='small'>Filter By</span>
          <Form.Item
            name="filertby"
          >
            <Select
              onChange={handlePresaleSearchChange}
              size="large"
              defaultValue="All Status"
            >
              {
                presaleStatusList.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}>
                    {item.name}
                  </Option>
                ))
              }

            </Select>
          </Form.Item>
        </Col>

        <Col lg="2" md="2" sm="2">
          <span className='small'>Sort By</span>
          <Form.Item
            name="sortby"
          >
            <Select
              onChange={handlePresaleSortChange}
              size="large"
              defaultValue="No Filter"
            >
              {
                presaleSortList.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}>
                    {item.name}
                  </Option>
                ))
              }

            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row className='mt-4'>
        <div className='col-lg-6'>
          <Row>
            <h6 className='text-center'>Add to trending list</h6>
            <div className='mt-3'>
              <div className='table-responsive'>
                <div className='table'>
                  <Table
                    dataSource={poolDataList}
                    columns={columns}
                    pagination={false}
                    loading={isPoolDataLoading}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="d-flex justify-content-center my-5">
                <Pagination
                  total={totalPools}
                  defaultPageSize={PAGE_LIMIT}
                  current={currentPage}
                  onChange={onChange}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className='col-lg-6'>
          <Row>
            <h6 className='text-center'>Trending list</h6>
            <div className='mt-3'>
              <div className='table-responsive'>
                <div className='table'>
                  <Table
                    loading={isTrendingDataLoading}
                    pagination={false}
                    dataSource={dataSource}
                    columns={trendingColumns}
                    rowKey="id"
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </Row>

          <div className='text-center'>
            <Button type='primary' className='text-dark' onClick={() => handleTrendingListUpdate()}>{trendingDataUpdating ? <Spin size="small" /> : 'Update'}</Button>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Trending