import { Card, Spin, message, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import usePoolStatus from '../../Hooks/usePoolStatus'
import { useWeb3React } from '@web3-react/core'
import useUserPresalePoolDetails from '../../Hooks/useUserPresalePoolDetails'
import {
    getMinAndMaxContributionAmount,
    getTotalContributorsCount,
    getTokensPerBNBInPool
} from '../../Blockchain/services/presale.service'
import NumberFormat from 'react-number-format'
import api from '../../Services/API'
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../../Redux/Auth'

function StatWidget(props) {

    const { Option } = Select;
    const dispatch = useDispatch()
    const { presaleSaleDetails, presaleAddress, setReload } = props
    const { userToken } = useSelector((state) => state.auth);
    const [minimumBuy, setMinimumBuy] = useState(0.0)
    const [maximumBuy, setMaximumBuy] = useState(0.0)
    const [totalContributorCount, setTotalContributorCount] = useState(0.0)
    const [tokenPerBNB, setTokenPerBNB] = useState(0.0)

    const [isMinMaxAmountLoading, setIsMinMaxAmountLoading] = useState(false)
    // const [isTotalContributorCountLoading, setIsTotalContributorCountLoading] = useState(false)
    const [isTokenPerBNBLoading, setIsTokenPerBNBLoading] = useState(false)
    const { account } = useWeb3React()
    const {
        isLoading: isUserContributionDetailsLoading,
        userPresalePoolDetails
    } = useUserPresalePoolDetails({
        poolAddress: presaleAddress,
        walletAddress: account,
        liquidityTokenName: presaleAddress?.liquidityTokenName
    })

    // pool status change related variables
    const [changedPoolStatus, setChangedPoolStatus] = useState(null)
    const [statWidgetChangeDetected, setStatWidgetChangeDetected] = useState(false)
    const [updatingLoad, setUpdatingLoad] = useState(false)

    const handlePresaleSearchChange = (value) => {
        setChangedPoolStatus(value)

        if (value !== poolStatus.statusName) {
            setStatWidgetChangeDetected(true)
        } else {
            setStatWidgetChangeDetected(false)
        }
    }

    const handleChange = async () => {
        setUpdatingLoad(true)
        try {
            await api.patch(`/api/v1/presale/update-by-pool-address/${presaleAddress}`,
            {
                status: changedPoolStatus
            }, {headers: { 'Authorization' : `Bearer ${userToken}` }})
            .then(res => {
                if(res.status === 200) {
                    message.success('Success!')
                    setReload(true)
                }
            })
        } catch (error) {
            if(error.response.status === 403) {
                dispatch(logout())
            }
            message.error(error.response.data.message)
        }
        setUpdatingLoad(false)
    }

    useEffect(() => {
        fetchMinMaxContributionAmounts()
        if (presaleAddress) {
            fetchTokenPerBNBAmountInPool()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presaleAddress, presaleSaleDetails])

    useEffect(() => {

        const interval = setInterval(() => {
            if (presaleAddress) {
                fetchTotalContributorCount(presaleAddress)
            }
            //update every 2 seconds 
        }, 5000)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presaleAddress])

    const fetchMinMaxContributionAmounts = async () => {
        try {
            setIsMinMaxAmountLoading(true)
            const minMaxAmountResponse = await getMinAndMaxContributionAmount(presaleAddress)
            setMinimumBuy(minMaxAmountResponse.minContributionAmount)
            setMaximumBuy(minMaxAmountResponse.maxContributionAmount)
            setIsMinMaxAmountLoading(false)
        } catch (error) {
            setMinimumBuy(0.0)
            setMaximumBuy(0.0)
            setIsMinMaxAmountLoading(false)
            console.error("ERROR Fetching min max contribution amounts : ", error)
        }
    }

    const fetchTotalContributorCount = async () => {
        try {
            //setIsTotalContributorCountLoading(true)
            const contributorCountResponse = await getTotalContributorsCount(presaleAddress)
            setTotalContributorCount(contributorCountResponse)
            //setIsTotalContributorCountLoading(false)
        } catch (error) {
            setTotalContributorCount(0)
            //setIsTotalContributorCountLoading(false)
            console.error("ERROR Fetching total contributor count : ", error)
        }
    }

    const fetchTokenPerBNBAmountInPool = async () => {
        try {
            setIsTokenPerBNBLoading(true)
            const tokenPerBNBResponse = await getTokensPerBNBInPool(presaleAddress)
            setTokenPerBNB(tokenPerBNBResponse)
            //setTotalContributorCount(contributorCountResponse)
            setIsTokenPerBNBLoading(false)
        } catch (error) {
            setTokenPerBNB(0)
            setIsTokenPerBNBLoading(false)
            console.error("ERROR Fetching tokens per bnb amount in the pool : ", error)
        }
    }

    const {
        isLoading: isPoolStatusLoading,
        poolStatus
    } = usePoolStatus({ poolAddress: presaleAddress })

    const presaleStatusList = [
        { name: "Live", value: 'live' },
        { name: "Upcoming", value: 'upcoming' },
        { name: "Filled", value: 'filled' },
        { name: "Finalized", value: 'finalized' },
        { name: "Ended", value: 'ended' },
        { name: "Canceled", value: 'canceled' },
        { name: "Paused", value: 'paused' },
      ];

    return (
        <div>
            <Card className="mt-4 kingsale-card-bg">
                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Status</span>
                    </div>

                    <div className='col-lg-6'>
                        {
                            isPoolStatusLoading ? (
                                <div className='loader text-end'>
                                    <Spin size="small" />
                                </div>
                            ) : (
                                <Select
                                onChange={handlePresaleSearchChange}
                                defaultValue={poolStatus?.statusName}
                                style={{
                                    width: '100%',
                                  }}
                                >
                                    {
                                        presaleStatusList.map(item => (
                                            <Option
                                                value={item.value}
                                                key={item.value}
                                            >
                                                {item.name}
                                            </Option>
                                        ))
                                    }
            
                                </Select>
                            )
                        }

                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Sale type</span>
                    </div>

                    <div>
                        <span className='primary-text'>Public</span>
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Minimum Buy</span>
                    </div>

                    <div>
                        {
                            isMinMaxAmountLoading ? (
                                <div className=''>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={minimumBuy ? minimumBuy : '0.00'}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    suffix={' ' + presaleSaleDetails?.liquidityTokenName}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Maximum Buy</span>
                    </div>

                    <div>
                        {
                            isMinMaxAmountLoading ? (
                                <div className=''>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={maximumBuy ? maximumBuy : '0.00'}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    suffix={' ' + presaleSaleDetails?.liquidityTokenName}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Total Contributors</span>
                    </div>

                    <div>
                        <NumberFormat
                            value={totalContributorCount ? totalContributorCount : '0'}
                            displayType={'text'}
                            thousandSeparator={true}
                        />
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>My Contribution</span>
                    </div>

                    <div>
                        {
                            isUserContributionDetailsLoading ? (
                                <div className=''>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={userPresalePoolDetails ? userPresalePoolDetails?.myContributionInBNB : '0.00'}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={' ' + presaleSaleDetails?.liquidityTokenName}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>My Reserved Tokens</span>
                    </div>

                    <div>
                        {
                            isUserContributionDetailsLoading ? (
                                <div className=''>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={userPresalePoolDetails ? userPresalePoolDetails?.userTokenReserved : '0.00'}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={presaleSaleDetails ? "  " + presaleSaleDetails?.tokenSymbol : ''}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />

                <div className='d-md-flex justify-content-between'>
                    <div>
                        <span>Fund Pool Rate</span>
                    </div>

                    <div>
                        {
                            isTokenPerBNBLoading ? (
                                <div className=''>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={tokenPerBNB ? tokenPerBNB : '0'}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={presaleSaleDetails ? "  " + presaleSaleDetails?.tokenSymbol : ''}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />
                
                <div className='text-center'>
                    {
                        updatingLoad ? 
                        <Spin size="small" />
                        :
                        <Button className='col-12 fw-bold' type="primary" disabled={!statWidgetChangeDetected} onClick={() => handleChange()}>Update</Button>
                    }
                </div>
            </Card>
        </div>
    )
}

export default StatWidget