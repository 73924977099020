import React, { useState } from 'react'
import { Form, Input, Button, Card, message, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { login } from '../../Redux/Auth'
import api from '../../Services/API';

function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loginLoading, setLoginLoading] = useState(false)

    const onFinish = async (values) => {
        setLoginLoading(true)
        try {
            await api.post("/api/v1/authenticate/login", {
                email: values.email, password: values.password
            })
            .then(res => {
                if(res.status === 200) {
                    message.success('Welcome!');
                    dispatch(login(res.data.payload)) 
                    navigate('/')
                }
            })
        } catch (error) {
            message.error(error.response.data.message)
        }
        setLoginLoading(false)
    }

    return (
        <div className='container mt-5 mb-5'>

            <Card title="Login" className='kingsale-card-bg col-lg-6 mx-auto'>
                <Form
                name="login"
                onFinish={onFinish}
                >
                    <span className='fw-bold small'>Email</span>
                    <Form.Item
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                        {
                            type: 'email',
                            message: 'Please input a valid email address!',
                        }
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <span className='fw-bold small'>Password</span>
                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        ]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>
                    
                    
                    <Form.Item className='text-center'>
                        <Button size="large" htmlType="submit" className='col-12 kingsale-primary-button' >
                            {
                                loginLoading ? <Spin size="small" /> : 'Login'
                            }
                        </Button>
                    </Form.Item>

                </Form>
                
                {/* <div className='text-center mt-5'>
                    <span>Don't have an account?</span><br />
                    <Link to="/register"><span className='fw-bold primary-text'>Register Now</span></Link>
                </div> */}
            </Card>

        </div>
    )
}

export default Login