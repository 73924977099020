/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { getPoolStatus } from "../Blockchain/services/presale.service"
export const usePoolStatus = (props) => {

  const { poolAddress, offChainPoolDetails } = props
  const [poolStatus, setPoolStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (poolAddress) {
      fetchPoolStatus(poolAddress)
    }
  }, [poolAddress])

  useEffect(() => {

    if (poolStatus && offChainPoolDetails) {
      const offChainLastUpdatedAtUTCTimestamp = DateTime.fromISO(offChainPoolDetails?.updatedAt)
      const currentTimestamp = DateTime.now()
      const diff = currentTimestamp.diff(offChainLastUpdatedAtUTCTimestamp, ['seconds']).toObject()
      const { seconds } = diff
      const TIME_ELAPSED_SECONDS = 10
      if (seconds > TIME_ELAPSED_SECONDS) {
        updateOffChainStatus(poolStatus.statusCode, poolAddress)
      } else {
        console.log("UPDATING STATUS SKIPPED")
      }
    }

  }, [poolStatus, offChainPoolDetails])

  const updateOffChainStatus = async (status, poolAddress) => {
    try {
      const payload = {
        status: status
      }
      let config = {
        method: 'patch',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/presale/update-by-pool-address/${poolAddress}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };
      const response = await axios(config)
      if (response.status === 200) {
        console.log("status update: success : ", status)
      }
    } catch (error) {
      console.log("ERROR while updating pool status off chain", error)
    }
  }

  const fetchPoolStatus = async (poolAddress) => {
    try {
      setIsLoading(true)
      const response = await getPoolStatus(poolAddress)
      setPoolStatus(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("ERROR HOOK : while fetching pool status ", error)
    }
  }

  return { poolStatus, isLoading }
}


export default usePoolStatus