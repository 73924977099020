import React from 'react'
import {
    GlobalOutlined,
    FacebookFilled,
    TwitterOutlined,
    GithubOutlined,
    RedditOutlined,
    InstagramFilled
} from '@ant-design/icons'

import Discord from '../../images/discord.png'
import Telegram from '../../images/telegram.png'

function Social(props) {
    const { presaleDetails, isPresaleLoading } = props
    return (
        <div>
            {
                isPresaleLoading ? (
                    <></>
                ) : (
                    <>
                        {
                            presaleDetails?.websiteLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.websiteLink}
                                target='_blank'
                                rel="noreferrer">
                                <GlobalOutlined size={20} className="" />
                            </a>
                        }
                        {
                            presaleDetails?.discordLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.discordLink}
                                target='_blank'
                                rel="noreferrer">
                                <img src={Discord} style={{ width: '18px' }} alt="discord" />
                            </a>
                        }
                        {
                            presaleDetails?.facebookLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.facebookLink}
                                target='_blank'
                                rel="noreferrer">
                                <FacebookFilled size={20} className="" />
                            </a>
                        }
                        {
                            presaleDetails?.githubLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.githubLink}
                                target='_blank'
                                rel="noreferrer">
                                <GithubOutlined size={20} className="" />
                            </a>
                        }
                        {
                            presaleDetails?.instagramLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.instagramLink}
                                target='_blank'
                                rel="noreferrer">
                                <InstagramFilled size={20} className="" />
                            </a>
                        }
                        {
                            presaleDetails?.redditLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.redditLink}
                                target='_blank'
                                rel="noreferrer">
                                <RedditOutlined size={20} className="" />
                            </a>
                        }
                        {
                            presaleDetails?.telegramLink &&
                            <a className="social-links-private-sale-profile"
                                href={presaleDetails.telegramLink}
                                target='_blank'
                                rel="noreferrer">
                                <img src={Telegram} style={{ width: '14px' }} alt="telegram" />
                            </a>
                        }
                        {
                            presaleDetails?.twitterLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={presaleDetails.twitterLink}
                                target='_blank'
                                rel="noreferrer">
                                <TwitterOutlined size={20} className="" />
                            </a>
                        }
                    </>


                )
            }
        </div>
    )
}

export default Social