import { configureStore } from "@reduxjs/toolkit";
import SidemenuMarginReducer from "./SidemenuMargin";
import WalletConnectReducer from "./WalletConnect";
import RoiModalReducer  from "./RoiModal";
import authReducer from "./Auth";
import ProjectDetailModalReducer from "./ProjectDetailModal";

export const Store = configureStore({
  reducer: {
    margin: SidemenuMarginReducer,
    connectModalState: WalletConnectReducer,
    roiModalState: RoiModalReducer,
    auth: authReducer,
    projectDetailModalState: ProjectDetailModalReducer
  }
});