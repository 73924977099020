import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Pagination, Form, Input, Select, Table, Tag, Image } from 'antd'
import axios from 'axios'
import './Presales.css'
import defaultLogo from '../../images/kingsfund.png'
import Social from '../PresaleDetails/Social'
import { Link } from 'react-router-dom'

function LivePresales() {

  const PAGE_LIMIT = 9
  const [isPoolDataLoading, setIsPoolDataLoading] = useState(false)
  const [poolDataList, setPoolDataList] = useState([])
  const [totalPools, setTotalPools] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchInput, setSearchInput] = useState('')
  const [filterValue, setFIlterValue] = useState('')
  const [sortByValue, setSortByValue] = useState('')

  const { Option } = Select;

  const presaleStatusList = [
    { name: "All Status", value: '' },
    { name: "Live", value: 'live' },
    { name: "Upcoming", value: 'upcoming' },
    { name: "Filled", value: 'filled' },
    { name: "Finalized", value: 'finalized' },
    { name: "Ended", value: 'ended' },
    { name: "Canceled", value: 'canceled' },
    { name: "Paused", value: 'paused' },
  ];

  const presaleSortList = [
    { name: 'No Filter', value: '' },
    { name: 'Hard Cap', value: 'hard-cap' },
    { name: 'Soft Cap', value: 'soft-cap' },
    { name: 'LP Percent', value: 'lp-percent' },
    { name: 'Start Time', value: 'start-time' },
    { name: 'End Time', value: 'end-time' }
  ]


  const handlePresaleSearchChange = (value) => {
    setFIlterValue(value)
  }

  const handlePresaleSortChange = (value) => {
    setSortByValue(value)
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchActivePools()
    }, 500)

    return () => clearTimeout(getData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'presaleName',
      render: (_, record) => (
        <>
          <Image
            fallback={defaultLogo}
            preview={false}
            src={record.logoURL ? record.logoURL : defaultLogo}
            style={{ width: '20px', marginRight: '10px', borderRadius: '50%' }}
            alt={record?.presaleName}
            className="card-logo" />
          <span>{record.presaleName}</span>
        </>
      )
    },
    {
      title: 'Coin',
      dataIndex: 'liquidityTokenName'
    },
    {
      title: 'Links',
      render: (text, record) => (<Social presaleDetails={record} isPresaleLoading={false} />)
    },
    {
      title: 'Status',
      render: (_, record) => (
        <Tag color="#faad14"><span className='text-dark'>{record.status}</span></Tag>
      )
    },
    {
      title: 'Details',
      render: (text, record) => (<Link to={`/presale-details/${record.poolContractAddress}`}>view</Link>)
    }
  ];

  const fetchActivePools = async (selectedFilleterValue) => {
    setIsPoolDataLoading(true)
    try {
      let endpoint = ''
      if (!selectedFilleterValue) {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/presale/all?page=${currentPage}&limit=${PAGE_LIMIT}&search=${searchInput ? searchInput : ''}&sortBy=${sortByValue}`
      } else {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/v1/presale/all?page=${currentPage}&limit=${PAGE_LIMIT}&status=${selectedFilleterValue}&search=${searchInput ? searchInput : ''}&sortBy=${sortByValue}`
      }
      const response = await axios.get(endpoint)
      if (response.status === 200) {
        const payload = response.data.payload
        if (payload) {
          const total = payload.meta.totalItems
          setTotalPools(total)
          setPoolDataList(payload.items)
        } else {
          setPoolDataList([])
        }
        setIsPoolDataLoading(false)
      }
    } catch (error) {
      setIsPoolDataLoading(false)
      console.log("ERROR while fetching active pools from API ", error)
    }
  }

  const onChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber)
    }
  }

  useEffect(() => {
    if (filterValue) {
      fetchActivePools(filterValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterValue, searchInput])

  return (
    <div className='mt-3 mb-5 presale-padding'>
      <Row>
        <Col lg="8" md="8" sm="8">
          <span className='small'>Enter token name, symbol or token address</span>
          <Form.Item
            name="searchInput"
            hasFeedback
          >
            <Input
              value={searchInput}
              name="tokenAddress"
              onChange={e => setSearchInput(e.target.value)}
              size='large'
              className='rounded-input'
              placeholder='Enter token name, symbol or token address' />
          </Form.Item>
        </Col>
        <Col lg="2" md="2" sm="2">
          <span className='small'>Filter By</span>
          <Form.Item
            name="filertby"
          >
            <Select
              onChange={handlePresaleSearchChange}
              size="large"
              defaultValue="All Status"
            >
              {
                presaleStatusList.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}>
                    {item.name}
                  </Option>
                ))
              }

            </Select>
          </Form.Item>
        </Col>

        <Col lg="2" md="2" sm="2">
          <span className='small'>Sort By</span>
          <Form.Item
            name="sortby"
          >
            <Select
              onChange={handlePresaleSortChange}
              size="large"
              defaultValue="No Filter"
            >
              {
                presaleSortList.map(item => (
                  <Option
                    value={item.value}
                    key={item.value}>
                    {item.name}
                  </Option>
                ))
              }

            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <div className='mt-3'>
          <div className='table-responsive'>
            <div className='table'>
              <Table dataSource={poolDataList} columns={columns} pagination={false} loading={isPoolDataLoading} size="small" />
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <Col lg="12" md="12" sm="12">
          <div className="d-flex justify-content-center my-5">
            <Pagination
              total={totalPools}
              defaultPageSize={PAGE_LIMIT}
              current={currentPage}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LivePresales