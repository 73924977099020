import { Card } from 'antd'
import React from 'react'
import { Row, Col } from 'reactstrap'

import fees from '../../images/dashboard/Fees.png'
import tokendist from '../../images/dashboard/Token Distribution.png'
import community from '../../images/dashboard/Community.png'
import kinglock from '../../images/dashboard/KingLock.png'
import kingstake from '../../images/dashboard/KingStake.png'
import trending from '../../images/dashboard/Trending.png'
import kyc from '../../images/dashboard/KYC.png'
import verified from '../../images/dashboard/Verfied Pre-sale.png'

function FeaturesSection() {
  return (
    <div className='mb-5'>
        <div className='text-center'>
            <h2 className='landing-text fw-bold'>Kingly Features for <span className='primary-text'>Token Presales</span></h2>

            <p className='landing-text fw-bold'>KingSale offers a suit of tools and features to help
            developers launch their projects and investors to participate in token presales. Take a look around to learn more!</p>
        </div>

        <div className='mt-5'>
            
            <Row className='col-lg-12 col-md-12 col-sm-11 mx-auto'>
                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={fees} alt="fees" style={{width: '70px'}} />
                        <h6 className='landing-text fw-bold mt-2'>Fees</h6>
                        <p className='landing-text text-muted small fw-bold'>3% of BNB/USDT raised thorugh the presale. 0% of token supply
                        to give each project their best chance.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={tokendist} alt="token_dist" style={{width: '70px'}} />
                        <h6 className='landing-text fw-bold mt-2'>Token Distrubution</h6>
                        <p className='landing-text text-muted small fw-bold'>Check the token distibution of each presale.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={community} alt="community" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>Community Participation</h6>
                        <p className='landing-text text-muted small fw-bold'>Vote on project safety and other features.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={kinglock} alt="kinglock" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>King Lock</h6>
                        <p className='landing-text text-muted small fw-bold'>Give investors security and confidence
                        by locking liquidity and team tokens through us.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={kingstake} alt="kingstake" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>King Staking</h6>
                        <p className='landing-text text-muted small fw-bold'>A place to create or participate
                        in staking pools for all tokens.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={trending} alt="trending" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>Trending Service</h6>
                        <p className='landing-text text-muted small fw-bold'>Track promising upcoming projects through
                        the KingSale trending banner. Trending rank is measured from user engagement and is not a paid service.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={kyc} alt="kyc" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>KYC Feature</h6>
                        <p className='landing-text text-muted small fw-bold'>We offer KYC to developers to givr their
                        investors more peace of mind.</p>
                    </Card>
                </Col>

                <Col className='mt-3' lg="3" md="3" sm="12">
                    <Card className='feature-card h-100 text-center'>
                        <img src={verified} alt="verified" style={{width: '70px'}} /> 
                        <h6 className='landing-text fw-bold mt-2'>Verified Pre-Sale Page</h6>
                        <p className='landing-text text-muted small fw-bold'>Ensure that all URLs point to our correct
                        address kingsale.finance, and not any scam site.</p>
                    </Card>
                </Col>

            </Row>
                

                
            
        </div>
    </div>
  )
}

export default FeaturesSection