import React, { useState, useEffect } from 'react'
import { Spin, Button, notification } from 'antd'
import { useWeb3React } from '@web3-react/core'
import {
  getPoolFinalizedStatus,
  finalizedPool
} from '../../../Blockchain/services/presale.service'

export default function FinalizedPoolButton(props) {
  const { isPoolStatusLoading, poolStatus, presaleAddress } = props

  const [canFinalizedThePool, setCanFinalizedThePool] = useState(false)
  const [isFinalizedPoolLoading, setIsFinalizedPoolLoading] = useState(false)
  const [isPoolFinalized, setIsPoolFinalized] = useState(false)
  const [poolButtonText, setPoolButtonText] = useState('Finalize The Pool')
  const { account, library } = useWeb3React()

  useEffect(() => {
    if (poolStatus) {
      if (poolStatus?.statusCode === 'ended' || poolStatus?.statusCode === 'filled') {
        setCanFinalizedThePool(true)
      } else {
        setCanFinalizedThePool(false)
      }
    } else {
      setCanFinalizedThePool(false)
    }
  }, [poolStatus])

  useEffect(() => {
    if (presaleAddress) {
      fetchPoolFinalizeStatus()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleAddress])

  const fetchPoolFinalizeStatus = async () => {
    try {
      const poolFinalizedResponse = await getPoolFinalizedStatus(presaleAddress)
      if (poolFinalizedResponse && poolFinalizedResponse.isPoolFinalized) {
        setIsPoolFinalized(true)
        setPoolButtonText('Pool has been finalized')
      } else {
        setIsPoolFinalized(false)
        setPoolButtonText('Finalize The Pool')
      }
    } catch (error) {
      console.error("ERROR while fetching pool finalize status ", error)
      setIsPoolFinalized(false)
    }
  }

  const handleFinalizedPool = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsFinalizedPoolLoading(false)
        return
      }

      setIsFinalizedPoolLoading(true)
      const response = await finalizedPool(presaleAddress, library.getSigner())
      console.log("response ", response)
      setIsFinalizedPoolLoading(false)
      await fetchPoolFinalizeStatus()
      notification['success']({
        message: 'Success',
        description: 'You have successfully finalized the private pool.',
      });
    } catch (error) {
      setIsFinalizedPoolLoading(false)
      console.error("ERROR while finalizing  the private sale pool ", error)
      notification['error']({
        message: 'Transaction execution failed',
        description: error,
      });

    }
  }

  return (
    <div className='finalized-pool-button-container'>

      {
        isPoolStatusLoading ? (
          <div className='loader text-centered'>
            <Spin size='small' />
          </div>
        ) : (
          <Button
            loading={isFinalizedPoolLoading}
            disabled={!canFinalizedThePool || isPoolFinalized}
            onClick={handleFinalizedPool}
            block
            type="primary">
            {poolButtonText}
          </Button>
        )
      }

    </div>
  )
}
